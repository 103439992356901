import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Process1 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_01.jpg'
import Process2 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_02.jpg'
import Process3 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_03.jpg'
import Process4 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_04.jpg'
import Process5 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_05.jpg'
import Process6 from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/HOL_06.jpg'
import Process1s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_01.jpg'
import Process2s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_02.jpg'
import Process3s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_03.jpg'
import Process4s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_04.jpg'
import Process5s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_05.jpg'
import Process6s from '../../../images/work/13_SALINAS_HOLADAY/PROCESS/450X280/HOL-SM_06.jpg'

const Residence13 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="OUTDOOR IMPROVEMENTS"
        residenceLocation="SALINAS, CALIFORNIA"
        infoList={['Outdoor improvements to existing single family residence: 460 SF gazebo with built-in BBQ and fire-burning fireplace; swimming pool with detached pool bathroom and fire pit; basketball court and putting green.', 'Design - 2017-2018', 'Construction - 2018', 'Contractor: John Laughton', 'Structural Engineer: North Coast Engineering, Inc.']}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process1,Process2,Process3,Process4,Process5,Process6]}
        processPhotosSmall={[Process1s,Process2s,Process3s,Process4s,Process5s,Process6s]}
        />
    </Container>
  </Layout>
)

export default Residence13;
